import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import globals, { initApp } from 'src/init.js';
import 'src/polyfills.js';
import GwConfig from 'src/gwconfig/GwConfig.js';
import 'src/gwconfig/assets.js';

// App attachment node
const rootNode = document.createElement("div");
document.getElementsByTagName('body')[0].appendChild(rootNode);


// Styling workaround
const bodyClasses = [
  'normalized',
]
document.body.classList.add(...bodyClasses);


// Hack in external resources
function externalsLoaded(event) {
  if (window && window.EcwidApp && (!window.extLoaded)) {
    window.extLoaded = true;
    import('src/gwconfig/external/functions.js').then(module => {
      Object.assign(window, module);
    });
  }
}
const scripts = [
  'https://djqizrxa6f10j.cloudfront.net/ecwid-sdk/js/1.2.5/ecwid-app.js',
  'https://djqizrxa6f10j.cloudfront.net/ecwid-sdk/css/1.3.1/ecwid-app-ui.min.js'
];
for (const script of scripts) {
  const scriptNode = document.createElement("script");
  scriptNode.setAttribute('src', script);
  scriptNode.onload = externalsLoaded;
  document.head.appendChild(scriptNode);
}
const styles = [
  'https://djqizrxa6f10j.cloudfront.net/ecwid-sdk/css/1.3.1/ecwid-app-ui.css'
];
for (const style of styles) {
  const linkNode = document.createElement("link");
  linkNode.setAttribute('rel', 'stylesheet');
  linkNode.setAttribute('type', 'text/css');
  linkNode.setAttribute('href', style);
  document.head.appendChild(linkNode);
}

// App modules
const modules = [
  'common', 'gwconfig'
];

// React
const root = ReactDom.createRoot(rootNode);
initApp(modules).then(() => {
  root.render(
    <Provider store={globals.Store}>
      <GwConfig moduleMaps={globals.ModuleMaps} />
    </Provider>
  );
});

