import React from 'react';
import { connect } from 'react-redux';
import { selectors } from 'src/gwconfig/store.js';

export function PrmList(props) {

  const { prmList = null } = props;
  const t = Array.isArray(prmList);
  return <div>
    {prmList === null && <>Loading...</>}
    {Array.isArray(prmList) && prmList.length === 0 && <>No recent payments.</>}
    {Array.isArray(prmList) && prmList.length > 0 &&
      <table>
        <tr>
          <th>Order Id</th>
          <th>Amount</th>
          <th>Payer</th>
          <th>Status</th>
          <th>Date</th>
        </tr>
        {prmList.map(prm => {
          return <tr>
            <td>{prm.orderId} </td>
            <td>{prm.amount} {prm.currency}</td>
            <td>{prm.payer ? prm.payer.email : "-"}</td>
            <td>{prm.status.status}</td>
            <td>{prm.createdAt}</td>
          </tr>;
        })}
      </table>}
  </div>;
};

export const PrmListConnected = connect((state, props) => {
  const mapedProps = {
    prmList: selectors.prmList(state)
  };
  return mapedProps;
})(PrmList);

export default PrmListConnected;