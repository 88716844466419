import React from 'react';
import { ConfigFormConnected } from 'src/gwconfig/components/ConfigForm.js';
import { Info } from 'src/gwconfig/components/Info.js';

export function GwConfig(props) {

  return <div className="settings-page main">
    <Info />
    <ConfigFormConnected />
  </div>;
};

export default GwConfig;