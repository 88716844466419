import React from 'react';
import PrmList from 'src/gwconfig/components/PrmList.js';

export function Info(props) {

  return <div className="settings-page__header">
    <div className="settings-page__titles settings-page__titles--left">
      <h1 className="settings-page__title">Inopay demo payment GW</h1>
      <div className="settings-page__subtitle">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
    </div>

    <div className="named-area">
      <div className="named-area__header">
        <div className="named-area__titles"><h4 className="named-area__title h4">Dashboard</h4>
          <span className="named-area__subtitle">Recent payments</span></div>
        <div className="named-area__description"></div>
        <div className="named-area__additional"></div>
      </div>
      <div className="named-area__body">
        <div className="a-card a-card--normal">
          <div className="a-card__paddings">
            <div className="titled-items-list fullWidth">
              <div className="titled-items-list__item titled-item">
                {/* <div className="titled-item__title">de Finibus Bonorum et Malorum</div>*/}
                <div className="titled-item__content">
                  <PrmList/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}
