import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InputField from 'src/gwconfig/components/InputField.js';
import SelectField from 'src/gwconfig/components/SelectField.js';
import { debouncedHandleCHange, handleChange } from 'src/gwconfig/functions.js';
import { actions } from 'src/gwconfig/store.js';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

function getOnPersonTypeChange(onChnage, setPersonType) {
  return (event) => {
    setPersonType(event.target.value || '');
    onChnage(event);
  };
}

const credentialsChanged = (name, dispatch) => debounce((event) => {
  handleChange(event);
  dispatch(actions.credentialsChanged(name, event.target.value));
}, 300);

export function ConfigForm(props) {

  const { dispatch } = props;

  const [handleMerchantTokenChanged] = useMemo(() => [
    credentialsChanged('merchantToken', dispatch)
  ], [dispatch]);

  const [personType, setPersonType] = useState('');

  const onPersonTypeChange = useMemo(
    () => getOnPersonTypeChange(debouncedHandleCHange, setPersonType),
    [setPersonType]
  );

  const typeRef = useRef(null);
  const usernameRef = useRef(null);
  const merchantTokenRef = useRef(null);

  useEffect(() => {
    if (typeRef.current && typeRef.current.value) {
      setPersonType(typeRef.current.value);
    }
  }, [typeRef.current]);

  return <div className="settings-page__section settings" >
    <div className="settings-page__titles settings-page__titles--left">
      <h2 className="settings-page__title">Settings</h2>
      <div className="settings-page__subtitle">Set up the application</div>
    </div>
    <div className="named-area">
      <div className="named-area__header">
        <div className="named-area__titles"><h4 className="named-area__title h4">Requisites</h4>
          <span className="named-area__subtitle">Banking info and requisites, etc.</span></div>
        <div className="named-area__description"></div>
        <div className="named-area__additional"></div>
      </div>
      <div className="named-area__body">
        <div className="a-card a-card--normal">
          <div className="a-card__paddings">
            <div className="titled-items-list">
              <div className="titled-items-list__item">
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField handleChange={debouncedHandleCHange} name="name" label="Receiver Name" />
                </div>
              </div>
              <div className="titled-items-list__item">
                <div className="fieldset fieldset--select fieldset--no-label fullWidth">
                  <div className="fieldset__title">Person type</div>
                  <SelectField ref={typeRef} handleChange={onPersonTypeChange} />
                </div>
              </div>
              <div className={clsx("titled-items-list__item", personType != 'company' && 'd-none' || '')}>
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField handleChange={debouncedHandleCHange} name="company_code" label="Company code" />
                </div>
              </div>
              <div className={clsx("titled-items-list__item", personType != 'natural_person' && 'd-none' || '')}>
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField handleChange={debouncedHandleCHange} name="person_code" label="Person code" />
                </div>
              </div>
              <div className="titled-items-list__item">
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField
                    handleChange={debouncedHandleCHange}
                    label="IBAN"
                    name="iban" />
                </div>
              </div>
              <div className="titled-items-list__item">
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField
                    handleChange={debouncedHandleCHange}
                    label="SWIFT"
                    name="swift" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="named-area">
      <div className="named-area__header">
        <div className="named-area__titles"><h4 className="named-area__title h4">Authorization</h4>
          <span className="named-area__subtitle">
            GW integration authorization info, etc.
          </span></div>
        <div className="named-area__description"></div>
        <div className="named-area__additional"></div>
      </div>
      <div className="named-area__body">
        <div className="a-card a-card--normal">
          <div className="a-card__paddings">
            <div className="titled-items-list">
              <div className="titled-items-list__item">
                <div className="fieldset fullWidth">
                  {false && <div className="fieldset__title">Instruction title</div>}
                  <InputField
                    ref={merchantTokenRef}
                    handleChange={handleMerchantTokenChanged}
                    name="merchantToken"
                    label="My API Token"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  </div >;

}

export const ConfigFormConnected = connect((state, props) => {
  const mapedProps = {
  };
  return mapedProps;
})(ConfigForm);