import React, {forwardRef} from 'react';
import Close from 'assets/gwconfig/close.svg';
import Ok from 'assets/gwconfig/ok.svg';
import Arrow from 'assets/gwconfig/arrow.svg';

export const SelectField = forwardRef((props, ref) =>  {

  const { label, placeholder, name, type = "text", handleChange } = props;

  return <div><div><div>
    <div className="field field--medium field--filled"><label className="field__label"></label>

      <select ref={ref} onChange={handleChange} data-name="person_type" data-visibility="public" className="field__select" tabIndex="1">
        <option value="company">Company</option>
        <option value="natural_person">Person</option>
      </select>

      <div className="field__placeholder"></div>
      <span className="field-state--success"><Ok /></span>
      <span className="field-state--close"><Close /></span>
      <span className="field__arrow"><Arrow /></span>
    </div>
  </div>
    {false && <div className="field__error" aria-hidden="true"></div>}
  </div>
    <div className="fieldset__note"></div>
  </div>;

});

export default SelectField;