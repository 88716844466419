import React, { forwardRef } from 'react';
import Close from 'assets/gwconfig/close.svg';
import Ok from 'assets/gwconfig/ok.svg';

export const InputField = forwardRef((props, ref) => {

  const { label, placeholder, name, type = "text", handleChange } = props;

  return <div>
    <div className="field field--medium field--filled">
      <span className="fieldset__svg-icon"></span>
      {label && <label className="field__label">{label}</label>}
      <input ref={ref} onChange={handleChange} data-name={name} data-visibility="private" type={type} className="field__input" tabIndex="4" maxLength="4096" />
      {placeholder && <div className="field__placeholder">{placeholder}</div>}
      <span className="field-state--success">
        <Ok />
      </span>
      <span className="field-state--close">
        <Close />
      </span>
    </div>
    {false && <div className="field__error" aria-hidden="true" ></div>}
    {false && <div className="fieldset__note">...and some description below</div>}
  </div>;
});

export default InputField;